define('ember-form-for/components/form-hint', ['exports', 'ember-form-for/templates/components/form-hint'], function (exports, _formHint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    tagName: '',
    layout: _formHint.default,

    joinedHintClasses: computed('hintClasses', function () {
      return (get(this, 'hintClasses') || []).join(' ');
    })
  });
});