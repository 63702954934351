define('ember-form-for/components/form-controls/reset', ['exports', 'ember-form-for/components/form-controls/button'], function (exports, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = _button.default.extend({
    type: 'reset',

    click: function click(e) {
      e.preventDefault();
      if (this.get('reset') !== undefined) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        this.get('reset').apply(undefined, _toConsumableArray(args));
      }
    }
  });
});