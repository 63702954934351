define("ember-form-for/templates/components/form-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rv4xUHsN",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"hint\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"id\",[23,\"hintId\"]],[12,\"class\",[23,\"joinedHintClasses\"]],[9],[1,[23,\"hint\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-hint.hbs"
    }
  });

  _exports.default = _default;
});