define('ember-cli-nprogress/initializers/nprogress', ['exports', 'nprogress'], function (exports, _nprogress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.scheduler = undefined;
  exports.initialize = initialize;
  var scheduler = exports.scheduler = Ember.run.later.bind(undefined, undefined);

  function initialize() {
    _nprogress.default.configure({ scheduler: scheduler });
  }

  exports.default = {
    name: 'nprogress',
    initialize: initialize
  };
});