define("ember-form-for/templates/components/form-controls/submit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "46aISL8I",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1,[[24,0,[]],[25,[\"activePromise\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"activePromise\",\"isPending\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"if\",[[25,[\"pending\"]],[25,[\"pending\"]],[25,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"activePromise\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"if\",[[25,[\"fulfilled\"]],[25,[\"fulfilled\"]],[25,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"activePromise\",\"isRejected\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"if\",[[25,[\"rejected\"]],[25,[\"rejected\"]],[25,[\"default\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,\"default\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-controls/submit.hbs"
    }
  });

  _exports.default = _default;
});