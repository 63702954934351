define('ember-form-for/components/form-fields/date-field', ['exports', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/utils/date'], function (exports, _textField, _date) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isEmpty = Ember.isEmpty;
  exports.default = _textField.default.extend({
    control: 'one-way-date',

    serializeValue: function serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toDateString)(value);
      }

      return value;
    },
    deserializeValue: function deserializeValue(value) {
      if (isEmpty(value)) {
        return null;
      }

      return new Date(value);
    }
  });
});