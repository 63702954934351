define("ember-changeset/-private/change", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var Change = function Change(value) {
        _classCallCheck(this, Change);

        this.value = value;
    };

    exports.default = Change;
});