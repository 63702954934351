define("ember-form-for/templates/components/form-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7B3/X3gA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"  \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[23,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[25,[\"required\"]]],null,{\"statements\":[[7,\"span\"],[11,\"class\",\"star\"],[9],[0,\"*\"],[10]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-label.hbs"
    }
  });

  _exports.default = _default;
});