define("ember-one-way-controls/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JZMze376",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"option\"],[12,\"value\",[29,\"if\",[[25,[\"optionValuePath\"]],[29,\"get\",[[25,[\"option\"]],[25,[\"optionValuePath\"]]],null],[25,[\"option\"]]],null]],[12,\"selected\",[29,\"one-way-select/contains\",[[25,[\"selected\"]],[25,[\"option\"]],[25,[\"optionValuePath\"]],[25,[\"optionTargetPath\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[26,1]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"optionComponent\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[25,[\"optionComponent\"]]],[[\"option\",\"index\",\"groupIndex\"],[[25,[\"option\"]],[25,[\"index\"]],[25,[\"groupIndex\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"optionLabelPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"get\",[[25,[\"option\"]],[25,[\"optionLabelPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"optionValuePath\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"get\",[[25,[\"option\"]],[25,[\"optionValuePath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"optionTargetPath\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"get\",[[25,[\"option\"]],[25,[\"optionTargetPath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[23,\"option\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-one-way-controls/templates/components/one-way-select/option.hbs"
    }
  });

  _exports.default = _default;
});