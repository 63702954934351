define('ember-form-for/components/form-controls/button', ['exports', 'ember-one-way-controls/-private/dynamic-attribute-bindings', 'ember-form-for/templates/components/form-controls/button'], function (exports, _dynamicAttributeBindings, _button) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      set = Ember.set,
      service = Ember.inject.service;


  var Button = Component.extend(_dynamicAttributeBindings.default, {
    layout: _button.default,
    tagName: 'button',
    type: 'button',
    attributeBindings: ['type'],
    config: service('ember-form-for/config'),

    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init: function init() {
      this._super.apply(this, arguments);

      var type = get(this, 'type');
      var buttonClasses = get(this, 'config.' + type + 'Classes');
      // @todo: this is bad practice, it should be fixed
      var classNames = get(this, 'classNames');
      set(this, 'classNames', (classNames || []).concat(buttonClasses));
    }
  });

  Button.reopenClass({
    positionalParams: ['label']
  });

  exports.default = Button;
});