define('ember-form-for/components/form-controls/submit', ['exports', 'ember-form-for/components/form-controls/button', 'ember-form-for/templates/components/form-controls/submit'], function (exports, _button, _submit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var alias = Ember.computed.alias,
      PromiseProxyMixin = Ember.PromiseProxyMixin,
      RSVP = Ember.RSVP,
      computed = Ember.computed,
      observer = Ember.observer;


  var SubmitButton = _button.default.extend({
    layout: _submit.default,
    tagName: 'button',
    type: 'submit',

    activePromise: undefined,

    classNames: ['async-button'],
    attributeBindings: ['disabled', 'type'],

    submit: alias('action'),
    default: 'Submit',
    pending: 'Submitting...',

    NON_ATTRIBUTE_BOUND_PROPS: ['click'],

    init: function init() {
      this._super.apply(this, arguments);
    },
    click: function click() {
      var _this = this;

      // PromiseProxyMixin allows us to use .isPending in the templates
      // RSVP.Promise is required to handle situation when submit function
      // returns non-promise.
      this.set('activePromise', Ember.Object.extend(PromiseProxyMixin).create({
        promise: new RSVP.Promise(function (resolve) {
          resolve(_this.get('submit')());
        })
      }));
      return false;
    },


    disabled: computed('activePromise.isPending', function () {
      if (this.get('activePromise.isPending') === true) {
        return true;
      } else {
        return false;
      }
    }),

    resetAction: observer('reset', 'activePromise.isFulfilled', 'activePromise.isRejected', function () {
      if (this.get('reset') && (this.get('activePromise.isFulfilled') || this.get('activePromise.isRejected'))) {
        this.set('activePromise', undefined);
      }
    })
  });

  SubmitButton.reopenClass({
    positionalParams: ['default']
  });

  exports.default = SubmitButton;
});