define('ember-form-for/components/form-errors', ['exports', 'ember-form-for/templates/components/form-errors'], function (exports, _formErrors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      computed = Ember.computed,
      get = Ember.get;
  exports.default = Component.extend({
    layout: _formErrors.default,
    tagName: '',

    limitedErrors: computed('errors.[]', 'maxErrors', function () {
      var errors = get(this, 'errors');
      var maxErrors = get(this, 'maxErrors');

      if (maxErrors) {
        return errors.slice(0, maxErrors);
      }

      return errors;
    }),

    joinedErrorClasses: computed('errorClasses', function () {
      return (get(this, 'errorClasses') || []).join(' ');
    })
  });
});