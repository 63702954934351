define("ember-data-change-tracker/transforms/object", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value && JSON.stringify(value);
    },
    deserialize: function deserialize(value) {
      if (Ember.isEmpty(value)) {
        return {};
      }

      if (Ember.typeOf(value) === "object") {
        return value;
      }

      if (Ember.typeOf(value) === 'string') {
        return JSON.parse(value);
      }
    }
  });

  _exports.default = _default;
});