define("ember-form-for/templates/components/form-fields/checkbox-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4v6X7ivH",
    "block": "{\"symbols\":[\"f\",\"option\"],\"statements\":[[4,\"form-field\",[[25,[\"propertyName\"]]],[[\"tagName\",\"class\",\"classNames\",\"disabled\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"object\",\"hint\",\"form\",\"label\"],[\"fieldset\",\"checkbox-group\",[25,[\"fieldClasses\"]],[25,[\"disabled\"]],[25,[\"errorClasses\"]],[25,[\"hintClasses\"]],[25,[\"inputClasses\"]],[25,[\"labelClasses\"]],[25,[\"object\"]],[25,[\"hint\"]],[25,[\"form\"]],[25,[\"label\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"form-label\",[[24,1,[\"labelText\"]]],[[\"required\",\"tagName\"],[[25,[\"required\"]],\"legend\"]]],false],[0,\"\\n\\n  \"],[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"form-fields/checkbox-group/option\",null,[[\"object\",\"propertyName\",\"option\",\"update\",\"disabled\",\"dir\",\"lang\",\"value\"],[[25,[\"object\"]],[25,[\"propertyName\"]],[24,2,[]],[29,\"action\",[[24,0,[]],\"updateSelection\",[24,2,[]]],null],[25,[\"disabled\"]],[25,[\"dir\"]],[25,[\"lang\"]],[24,2,[]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[1,[24,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[24,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/checkbox-group.hbs"
    }
  });

  _exports.default = _default;
});