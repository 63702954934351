define('ember-form-for/components/fields-for', ['exports', 'ember-form-for/templates/components/fields-for'], function (exports, _fieldsFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;


  var FieldsForComponent = Component.extend({
    layout: _fieldsFor.default,

    tagName: '',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  FieldsForComponent.reopenClass({
    positionalParams: ['object']
  });

  exports.default = FieldsForComponent;
});