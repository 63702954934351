define('ember-form-for/mixins/form-field', ['exports', 'ember-form-for/utils/titlecase'], function (exports, _titlecase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var assert = Ember.assert,
      computed = Ember.computed,
      get = Ember.get,
      guidFor = Ember.guidFor,
      set = Ember.set,
      typeOf = Ember.typeOf,
      Mixin = Ember.Mixin;
  exports.default = Mixin.create({
    instrumentDisplay: '{{form-field}}',

    didReceiveAttrs: function didReceiveAttrs() {
      assert(this.instrumentDisplay + ' requires propertyName to be set', typeOf(get(this, 'propertyName')) === 'string');

      var objectType = typeOf(get(this, 'object'));
      assert(this.instrumentDisplay + ' requires object to be set', objectType === 'object' || objectType === 'instance');

      this._setupLabel();

      this._super.apply(this, arguments);
    },


    identifier: computed('object', 'propertyName', 'value', function () {
      return this._identifier();
    }),

    _identifier: function _identifier() {
      return guidFor(get(this, 'object')) + '_' + get(this, 'propertyName');
    },
    _setupLabel: function _setupLabel() {
      set(this, 'label', get(this, 'label') || (0, _titlecase.default)(get(this, 'propertyName')));
    }
  });
});