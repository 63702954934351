define("ember-one-way-controls/templates/components/one-way-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jkt58XfQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-one-way-controls/templates/components/one-way-checkbox.hbs"
    }
  });

  _exports.default = _default;
});