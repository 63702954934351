define('ember-cli-moment-transform/transforms/moment-utc', ['exports', 'moment', 'ember-cli-moment-transform/transforms/moment'], function (exports, _moment, _moment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _moment2.default.extend({
    deserialize: function deserialize(serialized) {
      var result = this._super(serialized);

      if (_moment.default.isMoment(result)) {
        return result.utc();
      }
    }
  });
});