define("ember-data-change-tracker/index", ["exports", "ember-data-change-tracker/initializer", "ember-data-change-tracker/mixins/keep-only-changed"], function (_exports, _initializer, _keepOnlyChanged) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "initializer", {
    enumerable: true,
    get: function get() {
      return _initializer.initializer;
    }
  });
  Object.defineProperty(_exports, "keepOnlyChanged", {
    enumerable: true,
    get: function get() {
      return _keepOnlyChanged.default;
    }
  });
});