define('ember-changeset/utils/is-promise', ['exports', 'ember-changeset/utils/is-object'], function (exports, _isObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = isPromise;

    function isPromiseLike(obj) {
        return !!obj && !!obj.then && !!obj.catch && !!obj.finally && Ember.typeOf(obj.then) === 'function' && Ember.typeOf(obj.catch) === 'function' && Ember.typeOf(obj.finally) === 'function';
    }
    function isPromise(obj) {
        return (0, _isObject.default)(obj) && isPromiseLike(obj);
    }
});