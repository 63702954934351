define("ember-data-change-tracker/model-ext", ["ember-data/model", "ember-data-change-tracker/tracker"], function (_model, _tracker) {
  "use strict";

  _model.default.reopen({
    /**
     * Did an attribute/association change?
     *
     * @param {String} key the attribute/association name
     * @param {Object} changed optional ember-data changedAttribute object
     * @returns {Boolean} true if value changed
     */
    didChange: function didChange(key, changed, options) {
      return _tracker.default.didChange(this, key, changed, options);
    },

    /**
     * Did any attribute/association change?
     *
     * returns object with:
     *  {key: value} = {attribute: true}
     *
     * If the the attribute changed, it will be included in this object
     *
     * @returns {*}
     */
    modelChanges: function modelChanges() {
      var changed = Ember.assign({}, this.changedAttributes());

      var trackerInfo = _tracker.default.metaInfo(this);

      for (var key in trackerInfo) {
        if (!changed[key] && trackerInfo.hasOwnProperty(key)) {
          if (this.didChange(key, changed)) {
            changed[key] = true;
          }
        }
      }

      return changed;
    },

    /**
     * Rollback all the changes on this model, for the keys you are
     * tracking.
     *
     * NOTE: Be sure you understand what keys you are tracking.
     * By default, tracker will save all keys, but if you set up
     * a model to 'only' track a limited set of keys, then the rollback
     * will only be limited to those keys
     *
     */
    rollback: function rollback() {
      var isNew = this.get('isNew');
      this.rollbackAttributes();

      if (isNew) {
        return;
      }

      var trackerInfo = _tracker.default.metaInfo(this);

      var rollbackData = _tracker.default.rollbackData(this, trackerInfo);

      var normalized = _tracker.default.normalize(this, rollbackData);

      this.store.push(normalized);
    },
    // alias for saveChanges method
    startTrack: function startTrack() {
      this.saveChanges();
    },

    /**
     * Save the current state of the model
     *
     * NOTE: This is needed when manually pushing data
     * to the store and ussing Ember < 2.10
     *
     * options like => {except: 'company'}
     *
     * @param {Object} options
     */
    saveChanges: function saveChanges(options) {
      _tracker.default.setupTracking(this);

      _tracker.default.saveChanges(this, options);

      _tracker.default.triggerIsDirtyReset(this);
    },
    saveTrackerChanges: function saveTrackerChanges(options) {
      this.saveChanges(options);
    },

    /**
     * Get value of the last known value tracker is saving for this key
     *
     * @param {String} key attribute/association name
     * @returns {*}
     */
    savedTrackerValue: function savedTrackerValue(key) {
      return _tracker.default.lastValue(this, key);
    },
    // save state when model is loaded or created if using auto save
    setupTrackerMetaData: Ember.on('ready', function () {
      if (_tracker.default.isIsDirtyEnabled(this)) {
        // this is experimental
        _tracker.default.initializeDirtiness(this);
      }

      if (_tracker.default.isAutoSaveEnabled(this)) {
        this.saveChanges();
      }
    }),
    // when model updates, update the tracked state if using auto save
    saveOnUpdate: Ember.on('didUpdate', function () {
      if (_tracker.default.isAutoSaveEnabled(this) || _tracker.default.isIsDirtyEnabled(this)) {
        this.saveChanges();
      }
    }),
    // when model creates, update the tracked state if using auto save
    saveOnCreate: Ember.on('didCreate', function () {
      if (_tracker.default.isAutoSaveEnabled(this) || _tracker.default.isIsDirtyEnabled(this)) {
        this.saveChanges();
      }
    }),
    // There is no didReload callback on models, so have to override reload
    reload: function reload() {
      var _this = this;

      var promise = this._super.apply(this, arguments);

      promise.then(function () {
        if (_tracker.default.isAutoSaveEnabled(_this)) {
          _this.saveChanges();
        }
      });
      return promise;
    },
    // when model deletes, remove any tracked state
    clearSavedAttributes: Ember.on('didDelete', function () {
      _tracker.default.clear(this);
    })
  });
});