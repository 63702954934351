define("ember-form-for/templates/components/form-fields/checkbox-group/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cVPGtekk",
    "block": "{\"symbols\":[\"fc\"],\"statements\":[[4,\"form-field\",[[25,[\"propertyName\"]]],[[\"control\",\"tagName\",\"object\",\"formName\",\"update\"],[\"one-way-checkbox\",\"li\",[25,[\"object\"]],[25,[\"formName\"]],[25,[\"update\"]]]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"label\"]],\"expected `fc.label` to be a contextual component but found a string. Did you mean `(component fc.label)`? ('ember-form-for/templates/components/form-fields/checkbox-group/option.hbs' @ L9:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"control\"]],\"expected `fc.control` to be a contextual component but found a string. Did you mean `(component fc.control)`? ('ember-form-for/templates/components/form-fields/checkbox-group/option.hbs' @ L10:C6) \"],null],[29,\"one-way-select/contains\",[[29,\"get\",[[25,[\"object\"]],[25,[\"propertyName\"]]],null],[25,[\"option\"]]],null]],[[\"disabled\",\"dir\",\"lang\",\"value\"],[[25,[\"disabled\"]],[25,[\"dir\"]],[25,[\"lang\"]],[25,[\"option\"]]]]],false],[0,\"\\n    \"],[1,[23,\"labelText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/checkbox-group/option.hbs"
    }
  });

  _exports.default = _default;
});