define('ember-form-for/components/form-fields/textarea-field', ['exports', 'ember-form-for/templates/components/form-fields/textarea-field'], function (exports, _textareaField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;


  var TextareaFieldComponent = Component.extend({
    tagName: '',
    layout: _textareaField.default,

    control: 'one-way-textarea',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  TextareaFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = TextareaFieldComponent;
});