define('ember-keyboard/mixins/activate-keyboard-on-init', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    activateKeyboardWhenStarted: Ember.on('init', function () {
      Ember.set(this, 'keyboardActivated', true);
    })
  });
});