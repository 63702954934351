define('ember-changeset/helpers/changeset-set', ['exports', 'ember-changeset/utils/is-changeset'], function (exports, _isChangeset) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.changesetSet = changesetSet;

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    /**
     * This is a drop in replacement for the `mut` helper
     *
     * @method changesetSet
     * @param params
     */
    function changesetSet(_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            obj = _ref2[0],
            path = _ref2[1];

        if ((0, _isChangeset.default)(obj)) {
            return function (value) {
                return obj.set(path, value);
            };
        }
    }
    exports.default = Ember.Helper.helper(changesetSet);
});