define('ember-cli-nprogress/index', ['exports', 'nprogress'], function (exports, _nprogress) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _nprogress.default;
    }
  });
});