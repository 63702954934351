define('ember-form-for/components/form-fields/custom-field', ['exports', 'ember-form-for/templates/components/form-fields/custom-field'], function (exports, _customField) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;


  var CustomFieldComponent = Component.extend({
    tagName: '',
    layout: _customField.default,

    control: 'one-way-input',

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    }
  });

  CustomFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = CustomFieldComponent;
});