define("ember-form-for/templates/components/form-errors", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gewe3ZWN",
    "block": "{\"symbols\":[\"error\",\"index\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"errors\"]]],null,{\"statements\":[[4,\"form-for/custom-tag\",null,[[\"tagName\",\"class\"],[[25,[\"errorsTagName\"]],[25,[\"joinedErrorClasses\"]]]],{\"statements\":[[4,\"each\",[[25,[\"limitedErrors\"]]],null,{\"statements\":[[4,\"form-for/custom-tag\",null,[[\"tagName\",\"id\",\"role\"],[[25,[\"messageTagName\"]],[29,\"concat\",[[25,[\"errorId\"]],\"-\",[24,2,[]]],null],\"alert\"]],{\"statements\":[[4,\"if\",[[26,3]],null,{\"statements\":[[0,\"          \"],[15,3,[[24,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,1,[\"message\"]]],null,{\"statements\":[[0,\"            \"],[1,[24,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[24,1,[]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1,2]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-errors.hbs"
    }
  });

  _exports.default = _default;
});