define("ember-form-for/utils/is-equal", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = isEqual;
  function isEqual(a, b) {
    return a === b;
  }
});