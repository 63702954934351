define('ember-form-for/components/form-fields/datetime-local-field', ['exports', 'ember-form-for/components/form-fields/text-field', 'ember-form-for/utils/date'], function (exports, _textField, _date) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _textField.default.extend({
    control: 'one-way-datetime-local',

    serializeValue: function serializeValue(value) {
      if (value instanceof Date) {
        return (0, _date.toDatetimeLocalString)(value);
      }

      return value;
    },
    deserializeValue: function deserializeValue(value) {
      if (value != null) {
        var offset = new Date(value).getTimezoneOffset();
        return new Date(value + (0, _date.formatTimeZoneOffset)(offset));
      }

      return value;
    }
  });
});