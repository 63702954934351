define('ember-form-for/components/form-field', ['exports', 'ember-form-for/templates/components/form-field', 'ember-form-for/utils/strings'], function (exports, _formField, _strings) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      dasherize = Ember.String.dasherize,
      assert = Ember.assert,
      computed = Ember.computed,
      _Ember$computed = Ember.computed,
      notEmpty = _Ember$computed.notEmpty,
      or = _Ember$computed.or,
      reads = _Ember$computed.reads,
      get = Ember.get,
      getWithDefault = Ember.getWithDefault,
      guidFor = Ember.guidFor,
      service = Ember.inject.service,
      isEmpty = Ember.isEmpty,
      isPresent = Ember.isPresent,
      mixin = Ember.mixin,
      observer = Ember.observer,
      set = Ember.set;


  var FormFieldComponent = Component.extend({
    layout: _formField.default,

    config: service('ember-form-for/config'),

    _defaultErrorsProperty: 'errors',
    errorsProperty: or('config.errorsProperty', '_defaultErrorsProperty'),

    errorsPath: function errorsPath(propertyName) {
      var errorsPath = this.get('config.errorsPath');
      var errorsProperty = this.get('errorsProperty');

      if (!isPresent(errorsPath)) {
        errorsPath = errorsProperty + '.PROPERTY_NAME';
      }

      return errorsPath.replace('PROPERTY_NAME', propertyName);
    },


    classNameBindings: [],

    concatenatedProperties: ['inputClasses', 'labelClasses', 'hintClasses', 'errorClasses'],

    control: 'one-way-input',

    init: function init() {
      this._super.apply(this, arguments);

      var fieldClasses = get(this, 'config.fieldClasses');

      this.classNames = this.classNames.concat(fieldClasses);

      this.classNameBindings = this.classNameBindings.slice();
      this.classNameBindings.push('hasErrors:' + get(this, 'config.fieldHasErrorClasses'));

      this.propertyNameDidChange();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      ['inputClasses', 'labelClasses', 'hintClasses', 'errorClasses'].forEach(function (type) {
        set(_this, '_' + type, (get(_this, type) || []).concat(get(_this, 'config.' + type)));
      });

      assert('{{form-field}} requires an object property to be passed in', get(this, 'object') != null);

      assert('{{form-field}} requires the propertyName property to be set', typeof get(this, 'propertyName') === 'string');

      set(this, 'modelName', this.getModelName());
    },


    propertyNameDidChange: observer('propertyName', 'errorsProperty', function () {
      var propertyName = get(this, 'propertyName');
      var errorsPath = 'object.' + this.errorsPath(propertyName);

      mixin(this, {
        rawValue: reads('object.' + propertyName),
        errors: reads(errorsPath),
        hasErrors: notEmpty(errorsPath)
      });
    }),

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    },


    labelText: computed('propertyName', 'label', 'i18n.locale', function () {
      var i18n = get(this, 'i18n');
      var label = get(this, 'label');

      if (isPresent(label)) {
        return label;
      } else if (isPresent(i18n)) {
        return i18n.t(get(this, 'labelI18nKey'));
      } else {
        return (0, _strings.humanize)(get(this, 'propertyName'));
      }
    }),

    labelI18nKey: computed('config.i18nKeyPrefix', 'modelName', 'propertyName', function () {
      return [get(this, 'config.i18nKeyPrefix'), dasherize(get(this, 'modelName') || ''), dasherize(get(this, 'propertyName') || '')].filter(function (x) {
        return !!x;
      }).join('.');
    }),

    fieldId: computed('object', 'form', 'propertyName', function () {
      var baseId = get(this, 'form') || get(this, 'elementId');
      return baseId + '_' + get(this, 'propertyName');
    }),

    fieldName: computed('object', 'modelName', 'propertyName', function () {
      return this._nameForObject() + '[' + get(this, 'propertyName') + ']';
    }),

    describedByValue: computed('hint', 'errors.[]', 'fieldId', function () {
      var ids = [];
      var hint = get(this, 'hint');
      var errors = get(this, 'errors');
      var fieldId = get(this, 'fieldId');

      if (isPresent(hint)) {
        ids.push(fieldId + '_hint');
      }

      if (isPresent(errors)) {
        errors.forEach(function (_, index) {
          ids.push(fieldId + '_error-' + index);
        });
      }

      return isEmpty(ids) ? null : ids.join(' ');
    }),

    _nameForObject: function _nameForObject() {
      return get(this, 'modelName') || guidFor(get(this, 'object'));
    },
    getModelName: function getModelName() {
      var formName = get(this, 'form');
      var modelName = get(this, 'object.modelName');
      var constructorName = get(this, 'object.constructor.modelName');
      var changesetConstructorName = get(this, 'object._content.constructor.modelName');

      return formName || modelName || constructorName || changesetConstructorName;
    },


    value: computed('rawValue', function () {
      var serializeValue = getWithDefault(this, 'serializeValue', function (value) {
        return value;
      });
      return serializeValue(get(this, 'rawValue'));
    }),

    actions: {
      processUpdate: function processUpdate(object, propertyName, value) {
        var rawValue = get(this, 'rawValue');
        var deserializeValue = getWithDefault(this, 'deserializeValue', function (value) {
          return value;
        });
        get(this, 'update')(object, propertyName, deserializeValue(value, rawValue));
      }
    }
  });

  FormFieldComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = FormFieldComponent;
});