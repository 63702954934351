define('ember-form-for/components/form-fields/radio-group', ['exports', 'ember-form-for/templates/components/form-fields/radio-group'], function (exports, _radioGroup) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      set = Ember.set;


  var RadioGroupComponent = Component.extend({
    tagName: '',
    layout: _radioGroup.default,

    update: function update() {
      set.apply(undefined, arguments);
    }
  });

  RadioGroupComponent.reopenClass({
    positionalParams: ['propertyName']
  });

  exports.default = RadioGroupComponent;
});