define('ember-form-for/utils/strings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var _Ember$String = Ember.String,
      capitalize = _Ember$String.capitalize,
      decamelize = _Ember$String.decamelize;


  var WORD_SEPERATORS = new RegExp('[-_\\. ]', 'g');

  var humanize = exports.humanize = function humanize(string) {
    return capitalize(decamelize(string.toString()).replace(WORD_SEPERATORS, ' '));
  };

  var titlecase = exports.titlecase = function titlecase(string) {
    return decamelize(string).split(WORD_SEPERATORS).map(function (w) {
      return capitalize(w);
    }).join(' ');
  };
});