define("ember-form-for/templates/components/form-fields/hidden-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "swqi5pmz",
    "block": "{\"symbols\":[\"f\"],\"statements\":[[4,\"form-field\",[[25,[\"propertyName\"]]],[[\"class\",\"classNames\",\"errorClasses\",\"hintClasses\",\"inputClasses\",\"labelClasses\",\"serializeValue\",\"deserializeValue\",\"object\",\"hint\",\"form\",\"label\",\"control\",\"update\"],[\"hidden-field\",[25,[\"fieldClasses\"]],[25,[\"errorClasses\"]],[25,[\"hintClasses\"]],[25,[\"inputClasses\"]],[25,[\"labelClasses\"]],[25,[\"serializeValue\"]],[25,[\"deserializeValue\"]],[25,[\"object\"]],[25,[\"hint\"]],[25,[\"form\"]],[25,[\"label\"]],[25,[\"control\"]],[29,\"action\",[[24,0,[]],[25,[\"update\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[29,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"control\"]],\"expected `f.control` to be a contextual component but found a string. Did you mean `(component f.control)`? ('ember-form-for/templates/components/form-fields/hidden-field.hbs' @ L16:C4) \"],null]],[[\"accept\",\"accesskey\",\"autocomplete\",\"autofocus\",\"autosave\",\"dir\",\"disabled\",\"hidden\",\"inputmode\",\"lang\",\"list\",\"max\",\"maxlength\",\"min\",\"minlength\",\"multiple\",\"pattern\",\"placeholder\",\"readonly\",\"required\",\"size\",\"spellcheck\",\"step\",\"tabindex\",\"title\"],[[25,[\"accept\"]],[25,[\"accesskey\"]],[25,[\"autocomplete\"]],[25,[\"autofocus\"]],[25,[\"autosave\"]],[25,[\"dir\"]],[25,[\"disabled\"]],[25,[\"hidden\"]],[25,[\"inputmode\"]],[25,[\"lang\"]],[25,[\"list\"]],[25,[\"max\"]],[25,[\"maxlength\"]],[25,[\"min\"]],[25,[\"minlength\"]],[25,[\"multiple\"]],[25,[\"pattern\"]],[25,[\"placeholder\"]],[25,[\"readonly\"]],[25,[\"required\"]],[25,[\"size\"]],[25,[\"spellcheck\"]],[25,[\"step\"]],[25,[\"tabindex\"]],[25,[\"title\"]]]]],false],[0,\"\\n  \"],[1,[24,1,[\"errors\"]],false],[0,\"\\n  \"],[1,[24,1,[\"hint\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-form-for/templates/components/form-fields/hidden-field.hbs"
    }
  });

  _exports.default = _default;
});