define('ember-form-for/components/form-for', ['exports', 'ember-form-for/templates/components/form-for'], function (exports, _formFor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      get = Ember.get,
      service = Ember.inject.service,
      isPresent = Ember.isPresent,
      schedule = Ember.run.schedule,
      set = Ember.set;


  var FormForComponent = Component.extend({
    layout: _formFor.default,

    tagName: 'form',

    config: service('ember-form-for/config'),

    attributeBindings: ['tabindex', 'form:id'],

    init: function init() {
      this._super.apply(this, arguments);

      var formClasses = get(this, 'config.formClasses');
      var classNames = get(this, 'classNames');
      set(this, 'classNames', (classNames || []).concat(formClasses));

      this.notifyPropertyChange();
    },


    submit: function submit(object) {
      return object.save();
    },
    reset: function reset(object) {
      return object.rollback();
    },

    update: function update(object, propertyName, value) {
      set(object, propertyName, value);
    },
    handleErrors: function handleErrors(object) {
      var _this = this;

      var errors = get(object, 'errors');

      if (errors) {
        for (var propertyName in errors) {
          if (isPresent(get(errors, propertyName))) {
            set(this, 'tabindex', -1);
            schedule('afterRender', function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }
              _this.element.focus();
            });
            break;
          }
        }
      }
    },


    actions: {
      submit: function submit(object) {
        var _this2 = this;

        var promise = get(this, 'submit')(object);

        set(this, 'tabindex', undefined);

        if (promise && typeof promise.finally === 'function') {
          promise.finally(function () {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }
            _this2.handleErrors(object);
          });
        } else {
          this.handleErrors(object);
        }

        return promise;
      }
    }
  });

  FormForComponent.reopenClass({
    positionalParams: ['object']
  });

  exports.default = FormForComponent;
});